var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{class:[
    'zg-html-text-area',
    !_vm.$vuetify.breakpoint.xsOnly && !_vm.dense ? 'zg-edit-text-area' : '' ],attrs:{"column":"","full-width":_vm.dense},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }$event.preventDefault();return _vm.tab()}}},[_c('v-layout',{attrs:{"align-center":""}},[_c('DefaultSubtitle',{staticClass:"ml-4",style:({ height: _vm.hideLabel ? '0px' : '' })},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_vm._t("header-items"),(_vm.showAppend)?[_vm._t("append")]:_vm._e()],2),_c('v-scroll-y-transition',[(!_vm.collapsed)?_c('vue-editor',{ref:"lta",class:[
        _vm.dense
          ? 'dense zg-autosave-textarea-icon zg-textarea-singleline zg-textarea-border'
          : 'no-dense',
        !_vm.disabled ? 'editor' : 'no-editor',
        _vm.horizontalMargin ? 'mx-4' : '',
        _vm.verticalMargin ? 'my-2' : '',
        'zg-rel-html' ],attrs:{"placeholder":_vm.disabled && _vm.text ? '' : _vm.placeholder,"disabled":_vm.disabled,"editor-toolbar":_vm.customToolbar},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}},[_c('template',{slot:"toolbar"},[_c('v-layout',{class:[
            'zg-auto-save-toolbar',
            _vm.$vuetify.breakpoint.xsOnly
                ? 'has-editor mobile'
                : 'has-editor' ]},[_vm._t("toolbar")],2)],1)],2):_vm._e()],1),_c('MaxlengthErrorMessage',{attrs:{"value":_vm.text,"maxlength":_vm.maxlength}}),_vm._t("footer-items")],2)}
var staticRenderFns = []

export { render, staticRenderFns }