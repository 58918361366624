<template>
  <v-layout
    column
    :full-width="dense"
    :class="[
      'zg-html-text-area',
      !$vuetify.breakpoint.xsOnly && !dense ? 'zg-edit-text-area' : '',
    ]"
    @keydown.tab.prevent="tab()"
  >
    <v-layout align-center>
      <DefaultSubtitle
        class="ml-4"
        :style="{ height: hideLabel ? '0px' : '' }"
      >
        {{ label }}
      </DefaultSubtitle>
      <slot name="header-items"></slot>
      <template v-if="showAppend">
        <slot name="append"></slot>
      </template>
    </v-layout>
    <v-scroll-y-transition>
      <vue-editor
        ref="lta"
        v-if="!collapsed"
        :placeholder="disabled && text ? '' : placeholder"
        v-model="text"
        :class="[
          dense
            ? 'dense zg-autosave-textarea-icon zg-textarea-singleline zg-textarea-border'
            : 'no-dense',
          !disabled ? 'editor' : 'no-editor',
          horizontalMargin ? 'mx-4' : '',
          verticalMargin ? 'my-2' : '',
          'zg-rel-html',
        ]"
        :disabled="disabled"
        :editor-toolbar="customToolbar"
      >
        <template slot="toolbar">
          <v-layout
            :class="[
              'zg-auto-save-toolbar',
              $vuetify.breakpoint.xsOnly
                  ? 'has-editor mobile'
                  : 'has-editor',
            ]"
          >
            <slot name="toolbar"></slot>
          </v-layout>
        </template>
      </vue-editor>
    </v-scroll-y-transition>
    <MaxlengthErrorMessage
      :value="text"
      :maxlength="maxlength"
    ></MaxlengthErrorMessage>
    <slot name="footer-items"></slot>
  </v-layout>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  props: {
    value: {},
    label: {},
    placeholder: {},
    disabled: {
      default: false,
    },
    rules: {},
    readOnly: {},
    collapsed: {
      default: false,
    },
    hideDetails: {
      default: false,
    },
    rows: {
      default: 3,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showAppend: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    horizontalMargin: {
      type: Boolean,
      default: false,
    },
    verticalMargin: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    enabledTabSwitchResponder: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: 20000,
    },
  },
  data() {
    return {
      customToolbar: [
        [{ header: [false] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }],
      ],
    };
  },
  components: {
    VueEditor,
    MaxlengthErrorMessage: () =>
      import("@/components/controls/MaxlengthErrorMessage"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
  },
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    blur() {
      this.$refs.lta.quill.blur();
    },
    getFormat() {
      return this.$refs.lta.quill.getFormat();
    },
    selection() {
      return this.$refs.lta.quill.selection;
    },
    tab() {
      if (!this.enabledTabSwitchResponder) {
        return;
      }
      this.$refs.lta.quill.history.undo();
    },
  },
};
</script>

<style>
.zg-rel-html {
  position: relative;
}
.zg-html-text-area {
}
.zg-textarea-singleline.v-textarea.v-text-field--enclosed.v-text-field--single-line
  textarea {
  margin-top: 0px !important;
}
.zg-textarea-border.v-input .v-input__slot {
  border: #cecece 1px solid !important;
  border-radius: 0.25rem !important;
  background-color: white;
}
.zg-autosave-textarea-icon.v-textarea.v-text-field--outlined
  .v-input__append-inner {
  margin-top: 5px !important;
}
</style>

<style scoped lang="scss">
.zg-auto-save-toolbar {
  position: absolute;
  z-index: 2;
  &.has-editor {
    right: 14px;
    padding-top: 9px;
    &.mobile {
      right: 4px !important;
    }
  }
  &.hasnt-editor {
    right: -6px;
    top: -6px;
  }
}
.zg-edit-text-area {
  width: 28vw;
}
.quillWrapper {
  background-color: white !important;
  border-radius: 0.25rem !important;
  border: 1px solid var(--v-border-base);

  &:focus-within {
    border-color: var(--v-border_dark-base) !important;
  }
}

::v-deep .ql-editor {
  font-family: 'ProximaNova';
  // font-weight: 700;
}

.no-dense::v-deep .ql-editor {
  min-height: 72px !important;
}
.dense::v-deep .ql-editor {
  min-height: 33px !important;
  color: black;
}
.editor::v-deep .ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}
.no-editor::v-deep .ql-toolbar.ql-snow {
  display: none !important;
}
.no-editor::v-deep .ql-blank {
  margin-top: 4px !important;
}
::v-deep .ql-container.ql-snow {
  border: none;
}
::v-deep .ql-color-label:hover {
  fill: var(--v-primary-base) !important;
}
::v-deep .ql-picker-label:hover {
  fill: var(--v-primary-base) !important;
}
::v-deep .ql-picker-label:hover .ql-color-label {
  fill: var(--v-primary-base) !important;
}
::v-deep .ql-active .ql-stroke {
  stroke: var(--v-primary-base) !important;
}
::v-deep .ql-color-picker:hover .ql-stroke {
  stroke: var(--v-primary-base) !important;
}
::v-deep button:hover .ql-stroke {
  stroke: var(--v-primary-base) !important;
}
::v-deep button:focus .ql-stroke {
  stroke: var(--v-primary-base) !important;
}
::v-deep button:hover .ql-fill {
  fill: var(--v-primary-base) !important;
}
::v-deep button:focus .ql-fill {
  fill: var(--v-primary-base) !important;
}
::v-deep .quillWrapper .ql-snow .ql-active .ql-fill  {
  fill: var(--v-primary-base);
}
::v-deep .quillWrapper .ql-snow .ql-stroke {
  stroke: var(--v-icons-base);
}
::v-deep .quillWrapper .ql-snow .ql-fill {
  fill: var(--v-icons-base);
}
::v-deep .quillWrapper .ql-toolbar button:hover {
  opacity: 0.8;
}
::v-deep .quillWrapper .ql-formats:nth-child(1) {
  display: none !important;
}
::v-deep .quillWrapper .ql-blank::before {
  color: rgba(0, 0, 0, 0.22) !important;
  font-style: normal !important;
  font-weight: 400;
}
</style>
