import { render, staticRenderFns } from "./HTMLTextArea.vue?vue&type=template&id=1f595c04&scoped=true&"
import script from "./HTMLTextArea.vue?vue&type=script&lang=js&"
export * from "./HTMLTextArea.vue?vue&type=script&lang=js&"
import style0 from "./HTMLTextArea.vue?vue&type=style&index=0&lang=css&"
import style1 from "./HTMLTextArea.vue?vue&type=style&index=1&id=1f595c04&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f595c04",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VLayout,VScrollYTransition})
